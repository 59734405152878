import SignaturePad from 'signature_pad'

$( document ).on('turbolinks:load', function() {
	$('#signatureCanvas').hide();
	$('#clearSignature').hide();

	$('input:radio[name="signature_bureau[requester_attributes][credit_bureau_consultation_authorization_attributes][authorizes]"]').change(function() {
	  if ($(this).val() == '1') {
	    $('#signatureCanvas').show();
	    $('#firmaaqui').show();
	    $('#clearSignature').show();
	    var canvas = document.getElementById("signatureCanvas");
	    var signaturePad = new SignaturePad(canvas, {
	      backgroundColor: "#fff"
	    });
	    $('input:radio[name="signature_bureau[requester_attributes][credit_bureau_consultation_authorization_attributes][authorizes]"]').change(function() {
	      if ($(this).val() == '0') {
	        signaturePad.clear();
	        $('#signatureCanvas').hide();
	        $('#firmaaqui').hide();
	        $('#clearSignature').hide();
	      }
	    });
	    $( "#clearSignature" ).click(function() {
	      signaturePad.clear();
	    });

	    $("#validateAndSubmitForm").click(function() {
	      if (signaturePad.isEmpty()) {
	        $( ".helperText" ).append( "<p style='color: red;'>Por favor firma en el recuadro</p>" );
	      } else {
	        let dataURL = signaturePad.toDataURL();
	        $("#signatureImage").val(dataURL);
	        if (dataURL) {
	          document.authorizationForm.submit();
	        }
	      }
	    });
	  }
	});

	$("#signatureCanvas").hide();
	$("#clearSignature").hide();

	$(
	  'input:radio[name="financing_request_bureau_authorization[requester_attributes][credit_bureau_consultation_authorization_attributes][authorizes]"]'
	).change(function() {
	  if ($(this).val() == "1") {
	    $("#signatureCanvas").show();
	    $("#firmaaqui").show();
	    $("#clearSignature").show();
	    var canvas = document.getElementById("signatureCanvas");
	    var signaturePad = new SignaturePad(canvas);
	    $(
	      'input:radio[name="financing_request_bureau_authorization[requester_attributes][credit_bureau_consultation_authorization_attributes][authorizes]"]'
	    ).change(function() {
	      if ($(this).val() == "0") {
	        signaturePad.clear();
	        $("#signatureCanvas").hide();
	        $("#firmaaqui").hide();
	        $("#clearSignature").hide();
	      }
	    });
	    $("#clearSignature").click(function() {
	      signaturePad.clear();
	    });

	    $("#validateAndSubmitForm").click(function() {
	    	if(!$('#checkbox_legal_representative').is(':checked')){
	        		$(".helperText").append(
	        			"<p style='color: red;'>Por favor verifica el cuadro de representante legal</p>"
	        			);	
	    	}else{
		      if (signaturePad.isEmpty()) {
		        $(".helperText").append(
		          "<p style='color: red;'>Por favor firma en el recuadro</p>"
		        );
		      } else {
		        let dataURL = signaturePad.toDataURL();
		        $("#signatureImage").val(dataURL);
		        if (dataURL) {
		          document.authorizationForm.submit();
		        }
		      }
	    		
	    	}
	    });
	  }
	});
});